import React,{ useState } from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { BlogList } from 'components';
import { Layout } from 'layouts';
import  CategoriesBlock  from 'components/CategoriesBlock';
import styled from '@emotion/styled';
import { Link } from 'gatsby';


const Header = styled.div`
display: flex;
 padding-bottom: 2%;
 padding-top: 2%;
// z-value: 200;
justify-content: center;
//padding: 0% 0 2% 0;
//background: ${props => props.theme.colors.black.blue};
font-size: 2rem;

@media (max-width: 800px) {
    padding-top: 10%;
    padding-bottom: 5%;
  }
`;
const BlogPosts = styled.div`
display: flex;
position: relative ;
//grid-gap: 5px;
//padding-top: 5%;
flex-direction: column;

margin: auto;
width: 80vw;

border-radius: 1rem;
-moz-border-radius: 1rem;
-webkit-border-radius::1rem 1rem;
//padding: 0% 0 2% 0;
background: ${props => props.theme.colors.black.blue};
box-shadow: 0 4px 120px rgba(0,0,0,0.1);

//font-size: 2rem;

@media (max-width: 800px) {
width: 95vw;
  }
`;

const Fspace = styled.div`
//display: flex;
height:1rem;
background: white;
@media (max-width: 800px) {
    // padding-top: 10%;
    // padding-bottom: 5%;
  }
`;
const Fwarp = styled.div`
display: flex;
flex-direction: column;
//height:20%;
@media (max-width: 800px) {
    // padding-top: 10%;
    // padding-bottom: 5%;
  }
`;

const Blog = ({ data }) => {
  const { edges } = data.blogs;
  //var karr = edges.filter(({node}) => node.frontmatter.category == ono)
  

  return (
    <Layout>
      <Header>
      Blog
      </Header>
      <Helmet title={'Limitless Blog'} />
      <BlogPosts>
      {        
        edges.map(({ node }) => (
<Fwarp>
        <BlogList
          key={node.id}
          cover={node.frontmatter.cover.childImageSharp.fluid}
          path={node.frontmatter.path}
          title={node.frontmatter.title}
          date={node.frontmatter.date}
          tags={node.frontmatter.tags}
          excerpt={node.excerpt}
        />
        <Fspace>

        </Fspace>
</Fwarp>
      ))
      }      
      </BlogPosts>
    </Layout>
  );
};

export default Blog;

Blog.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            excerpt: PropTypes.string,
            frontmatter: PropTypes.shape({
              cover: PropTypes.object.isRequired,
              path: PropTypes.string.isRequired,
              title: PropTypes.string.isRequired,
              date: PropTypes.string.isRequired,
              tags: PropTypes.array,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
};

export const query = graphql`
  query {
    blogs: allMarkdownRemark(
      filter: { fileAbsolutePath: { glob: "**/blogs/**/*.md" } frontmatter: { published: { eq: false} }}
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 500)
          frontmatter {
            path
            date
            title
            category
            cover {
              childImageSharp {
                fluid(
                  maxWidth: 1000
                  quality: 100
                  traceSVG: { color: "#2B2B2F" }
                ) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`;
