import React from 'react';
//import { Link } from 'gatsby';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const CatContainer = styled.div`
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  //flex-wrap: unwrap;
  justify-content: left;
  // a {
  //   //margin: 0 1rem 1rem 0;
  //   color: ${props => props.theme.colors.black.blue};
  //   padding-left: 10rem;
  //   background: ${props => props.theme.colors.white.grey};
  //   border-radius: 10px;
  //   &:hover {
  //     color: ${props => props.theme.colors.white.light};
  //     background: ${props => props.theme.colors.primary.light};
  //     border: ${props => props.theme.colors.primary.light};
  //   }
  // }
`;
const OuterrContainer = styled.div`
border: 40px;
`;


const CategoriesBlock = ({ list }) => (
  <CatContainer>
    {list &&
      list.map(tag => {
        //const upperTag = tag.title;  
        return (
          <OuterrContainer style= {{ }}>
          {tag.title}
          </OuterrContainer>
        );
      })}
  </CatContainer>
);

export default CategoriesBlock;

CategoriesBlock.propTypes = {
  list: PropTypes.array,
};

